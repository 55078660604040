import { Dialog, DialogContent, DialogTitle, Typography, Button, Stack } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function ModalBrowserIncompatible({ opened }) {
  return (
    <Dialog open={opened} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <ErrorOutlineIcon sx={{ fontSize: 64, color: 'warning.main' }} />
      </DialogTitle>
      <DialogContent sx={{ pb: 3 }}>
        <Stack spacing={3} alignItems="center">
          <Typography variant="h6" align="center">
            Navegador Incompatível
          </Typography>
          <Typography align="center">
            Para uma melhor experiência, recomendamos que você utilize o Google Chrome.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="https://www.google.com/chrome"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ mt: 2 }}
          >
            Baixar Google Chrome
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
} 